import {Component, Input, OnInit} from '@angular/core';
import {IRequestMasterData} from "../../interfaces/i-request-master-data";
import {FormBuilder, FormGroup} from "@angular/forms";
import {catchError, forkJoin, map, of} from "rxjs";
import {IDepartment} from "../../interfaces/i-departments";
import {IWorkFlowComponentResolvedData} from "../../interfaces/i-resolvers";
import {DepartmentsService} from "../../services/departments.service";
import {UsersService} from "../../services/users.service";
import {IUser} from "../../interfaces/i-users";
import {AlertsService} from "@clavisco/alerts";

@Component({
  selector: 'app-request-master-data-info',
  templateUrl: './request-master-data-info.component.html',
  styleUrls: ['./request-master-data-info.component.scss']
})
export class RequestMasterDataInfoComponent implements OnInit {

  @Input() requestMasterData!: IRequestMasterData;

  requestMasterDataForm!:  FormGroup;

  constructor(private fb: FormBuilder,
              private departmentService: DepartmentsService,
              private userService: UsersService,
              private alertsService: AlertsService) { }

  ngOnInit(): void {
    this.LoadDataForm();

    this.requestMasterDataForm.patchValue(this.requestMasterData);

    this.LoadRequestingUserAndRequestingDepartment();

  }

  /**
   *  Initializes the form group for requesting master data.
   */
  LoadDataForm(): void {
    this.requestMasterDataForm = this.fb.group({
      ItemType: [null],
      Business: [null],
      Family: [null],
      Grouping: [null],
      ItemGroup: [null],
      Maker: [null],
      AdditionalIdentification: [null],
      StandardWarehouse: [null],
      RequestingUser: [null],
      RequestingDepartment: [null],
      Country: [null],
      Company: [null],
      IsInventoryItem: [{ value: false, disabled: true }],
      IsPurchaseItem: [{ value: false, disabled: true }],
      IsSaleItem: [{ value: false, disabled: true }],
      Observations: [null],
      ProvisioningMode: [null],
      ExplosionMaterialList: [null],
      ProductionUnit: [null]
    })

  }

  /**
   * The user who created the request and the department are added to the from group
   */
  LoadRequestingUserAndRequestingDepartment(): void{

     forkJoin({
      departmentResponse: this.departmentService.Get<IDepartment[]>(true),
      userResponce: this.userService.Get<IUser[]>(true),
    }).subscribe({
       next: (callback =>{
         let requestingUser = callback.userResponce.Data.find(user=>
           user.UserEmail === this.requestMasterData.CreatedBy)?.UserName || '';

         let requestingDepartment = callback.departmentResponse.Data.find(department =>
           department.Id === this.requestMasterData.DepartmentId)?.Name || '';

         this.requestMasterDataForm.get('RequestingUser')?.patchValue(requestingUser);
         this.requestMasterDataForm.get('RequestingDepartment')?.patchValue(requestingDepartment);

    }),
     error:(err =>{
       this.alertsService.ShowAlert({HttpErrorResponse:err});
       })
     });

  }


}
