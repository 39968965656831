 <mat-form-field appearance="outline"  class="search-field">
  <mat-label>{{label}}</mat-label>

   <ng-container *ngIf="controlName && form; else formControlInput">
     <form [formGroup]="form">
       <input #inputField placeholder="Buscar {{label?.toLowerCase()}}" (focus)="onFocusInput$.next(true)"
              type="text" matInput [readonly]="disabled"
              [formControlName]="controlName" [matAutocomplete]="auto">
     </form>
   </ng-container>

   <ng-template  #formControlInput>
     <ng-container *ngIf="control; else localFormControl">
       <input #inputField placeholder="Buscar {{label?.toLowerCase()}}" (focus)="onFocusInput$.next(true)"
              type="text" matInput [readonly]="disabled"
              [formControl]="control" [matAutocomplete]="auto">
     </ng-container>
   </ng-template>

   <ng-template #localFormControl>
     <input #inputField placeholder="Buscar {{label?.toLowerCase()}}" (focus)="onFocusInput$.next(true)"
            type="text" matInput [readonly]="disabled"
            [formControl]="localControl" [matAutocomplete]="auto">
   </ng-template>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="DisplayWithWrapper" (optionSelected)="onSelectOption($event.option.value)">
    <mat-option *ngFor="let bp of dataSource$ | async" [value]="bp">
      {{ Display(bp) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
