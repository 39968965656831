import { Pipe, PipeTransform } from '@angular/core';
import {WorkflowDepartmentStates} from "../enums/enums";

@Pipe({
  name: 'workflowDepartmentsStatesIcon'
})
export class WorkflowDepartmentsStatesIconPipe implements PipeTransform {

  transform(value: WorkflowDepartmentStates): string {
    switch (value) {
      case WorkflowDepartmentStates.COMPLETED:
        return "task";
      case WorkflowDepartmentStates.EMPTY:
        return "description";
      case WorkflowDepartmentStates.REJECTED:
        return "restore_page";
      case WorkflowDepartmentStates.SAVED:
        return "edit_document";
      default:
        return "";
    }
  }

}
