<section>
  <form [formGroup]="requestMasterDataForm" >

    <section class="grid-container">

      <div class=" grid-container col-lg-12">
        <mat-form-field appearance="outline" class="col-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Solicita</mat-label>
          <input type="text" matInput formControlName="RequestingUser" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Negocio</mat-label>
          <input type="text" matInput formControlName="Business" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Área solicitante</mat-label>
          <input type="text" matInput formControlName="RequestingDepartment" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>País</mat-label>
          <input type="text" matInput formControlName="Country" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Empresa</mat-label>
          <input type="text" matInput formControlName="Company" readonly>
        </mat-form-field>

        <div class="col-lg-12 grid-container margin-toggle">
          <mat-slide-toggle formControlName="IsInventoryItem" class="col-lg-2 col-xs-4 col-sm-6">
            Inventariable
          </mat-slide-toggle>

          <mat-slide-toggle formControlName="IsPurchaseItem" class="col-lg-2 col-xs-4 col-sm-6">
            Compras
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="IsSaleItem" class="col-lg-2 col-xs-4 col-sm-6">
            Ventas
          </mat-slide-toggle>
        </div>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Tipo de artículo</mat-label>
          <input type="text" matInput formControlName="ItemType" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Identificación adicional</mat-label>
          <input type="text" matInput formControlName="AdditionalIdentification" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Almacén estándar</mat-label>
          <input type="text" matInput formControlName="StandardWarehouse" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Familia</mat-label>
          <input type="text" matInput formControlName="Family" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Modo aprovisionamiento</mat-label>
          <input type="text" matInput formControlName="ProvisioningMode" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Agrupación</mat-label>
          <input type="text" matInput formControlName="Grouping" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Explosión lista materiales</mat-label>
          <input type="text" matInput formControlName="ExplosionMaterialList" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Grupo artículo</mat-label>
          <input type="text" matInput formControlName="ItemGroup" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Fabricante</mat-label>
          <input type="text" matInput formControlName="Maker" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-3 col-lt-lg-4 col-gt-sm-6 col-sm-12">
          <mat-label>Und producción</mat-label>
          <input type="text" matInput formControlName="ProductionUnit" readonly>
        </mat-form-field>

      </div>

      <mat-form-field appearance="outline" class="col-lg-12">
        <mat-label>Observaciones</mat-label>
        <textarea matInput formControlName="Observations" rows="5" readonly></textarea>
      </mat-form-field>



<!--      <div  [ngClass]="!requestMasterData.Image ? 'col-lg-12' : 'col-lg-8 col-lt-lg-6 col-md-12'" class="grid-container" >-->
<!--        -->
<!--      </div>-->

<!--      <div class="col-lg-4 col-gt-md-12 grid-container">-->
<!--      </div>-->



    </section>
  </form>
</section>
