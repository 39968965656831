import {Component, Inject, OnInit} from '@angular/core';
import {CLPrint, Repository, Structures} from "@clavisco/core";
import {environment} from "../environments/environment";
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {OverlayService} from "@clavisco/overlay";
import {CLToastType} from "@clavisco/alerts";
import {Login} from "@clavisco/login";
import {SharedService} from "./shared/shared.service";
import {delay, filter, tap} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {ICLTableState, ITableGlobalConfiguration} from "@clavisco/table";
import {CL_CHANNEL, ICLEvent, LinkerService, Run, StepDown} from "@clavisco/linker";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit{


  constructor(
    private router: Router,
    private overlayService: OverlayService,
    private sharedService: SharedService,
    @Inject('LinkerService') private linkerService: LinkerService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }
  ngOnInit(): void {
    this.OnLoad();
  }
  private OnLoad(): void {
    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.TABL,
      settings: {
        backgroundTransparent: true
      } as ITableGlobalConfiguration
    });

    // Configure settings for ALERTS token in the Repository Behavior
    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.ALERTS,
      settings: {
        closeText: 'X',
        darkMode: false,
        duration: 6000,
        horizontalPosition: 'center',
        type: CLToastType.SUCCESS,
        verticalPosition: 'bottom'
      },
      override: true
    });

    // Configure API URL for the 'Shared' token in the Repository Behavior
    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      value: environment.apiUrl
    });

    // Configure maximum value for the 'NotificationCenter' token in the Repository Behavior
    Repository.Behavior.SetTokenConfiguration({
      token: 'NotificationCenter',
      setting: 'max',
      value: '400'
    });

    // Subscribe to router events to perform actions based on navigation events
    this.router.events
      .subscribe({
        next: (callback) => {
          // On navigation start, show loading overlay
          if (callback instanceof NavigationStart) {
            this.overlayService.OnGet();
          }

          // On navigation end, hide loading overlay
          if (callback instanceof NavigationEnd) {
            this.overlayService.Drop();
          }

          // Handle navigation errors or cancellations
          if (callback instanceof NavigationError || callback instanceof NavigationCancel) {
            // Redirect to home if not on login or root page, and hide loading overlay
            if (!['/login', '/'].includes(this.router.url)) this.router.navigate(['home']);
            this.overlayService.Drop();
          }
        }
      });


      //Request the recovery token
      Repository.Behavior.SetTokenConfiguration({
        token: Structures.Enums.TOKENS.LOGN,
        setting: 'sendRecoverPasswordEmail',
        value: {
          urlVariableToReplaceWithEmail: '#EMAIL',
          endpointToRequest: `Users/#EMAIL/RecoveryPassword`
        } as Login.Structures.ISendRecoverPasswordEmailConfig
      })


     //Change the password as such in the recovery

      Repository.Behavior.SetTokenConfiguration({
        token: Structures.Enums.TOKENS.LOGN,
        setting: 'recoverPassword',
        value: {
          endpointToRequest: '' +
            'Users/ChangeRecoveryPassword'
        }  as Login.Structures.IChangePasswordConfig
      })

     //Change your password

      Repository.Behavior.SetTokenConfiguration({
        token: Structures.Enums.TOKENS.LOGN,
        setting: 'changePassword',
        value: {
          endpointToRequest: 'Users/ChangePassword'
        } as Login.Structures.IChangePasswordConfig
      })

    // Used to recalculate the taps and action buttons container width
    this.sharedService.OnPageInit()
      .pipe(delay(0))
      .subscribe({
        next: (result) => {
          let matTabHeader = this.document.querySelector(".mat-tab-header");
          let btnContainer = this.document.querySelector(".actions-container .btn-container");
          let actionButtonsContainer = this.document.querySelector(".actions-container");

          if (matTabHeader) {
            let matTabHeaderHeight = matTabHeader['offsetHeight' as keyof object];
            actionButtonsContainer?.setAttribute('style', `height: ${matTabHeaderHeight}px`);
          }

          let newMatTabHeaderWidth = btnContainer ? btnContainer['offsetWidth' as keyof object] : 0;

          matTabHeader?.setAttribute("style", `width: calc(100% - ${newMatTabHeaderWidth}px);`);
        }
      });
  }
}
