import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private readonly CONTROLLER = 'Settings';

  constructor(private http: HttpClient) {
  }

  /**
   * Retrieves settings from the API based on an optional code.
   * @param _code Optional code parameter for filtering settings.
   * @returns {Observable<Structures.Interfaces.ICLResponse<T>>} An observable containing the API response with settings.
   */
  Get<T>(_code?: string): Observable<Structures.Interfaces.ICLResponse<T>> {
    let path = this.CONTROLLER;

    if (_code) {
      path += `/?code=${_code}`;
    }

    return this.http.get<Structures.Interfaces.ICLResponse<T>>(path)
  }
}
