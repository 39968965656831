import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatChipsModule  } from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from "@angular/material/menu";
import {MatNativeDateModule} from "@angular/material/core";
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import {WorkflowStateIconPipe} from "../pipes/workflow-state-icon.pipe";
import {ScrollingModule} from "@angular/cdk/scrolling";
import { RequestMasterDataInfoComponent } from './request-master-data-info/request-master-data-info.component';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import {WorkflowDepartmentsStatesIconPipe} from "../pipes/workflow-departments-states-icon.pipe";
@NgModule({
  declarations: [
    ActionButtonsComponent,
    AutocompleteComponent,
    WorkflowStateIconPipe,
    RequestMasterDataInfoComponent,
    SelectCompanyComponent,
    SelectCheckAllComponent,
    WorkflowDepartmentsStatesIconPipe
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    MatGridListModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatChipsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatNativeDateModule,
  ],
  exports: [
    DragDropModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    MatGridListModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatChipsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatNativeDateModule,
    ActionButtonsComponent,
    AutocompleteComponent,
    WorkflowStateIconPipe,
    ScrollingModule,
    RequestMasterDataInfoComponent,
    SelectCompanyComponent,
    WorkflowDepartmentsStatesIconPipe
  ]
})
export class SharedModule { }
