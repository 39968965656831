import { Injectable } from '@angular/core';
import {Repository} from "@clavisco/core";
import {StorageKey} from "../enums/e-storage-keys";
import {Router} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {WorkflowsStates} from "../enums/enums";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private readonly currentPage$: Subject<string> = new Subject<string>();
  private readonly onPageInit$: Subject<boolean> = new Subject<boolean>();
  constructor(private router: Router) {
  }

  /**
   * Logs out the user by clearing stored session data and optionally navigating to the login page.
   * @param {_shouldNavigate} boolean - Indicates whether to navigate to the login page after logout. Default is true.
   * @returns {void}
   */
  Logout(_shouldNavigate: boolean = true): void {
    // Delete stored session-related data
    Repository.Behavior.DeleteStorageObject(StorageKey.Session);
    Repository.Behavior.DeleteStorageObject(StorageKey.Permissions);
    Repository.Behavior.DeleteStorageObject(StorageKey.Menu);

    // Optionally navigate to the login page
    if (_shouldNavigate) {
      this.router.navigate(['/login']);
    }
  }


  /**
   * Obtains the current route segment by extracting it from the full URL.
   * @returns {string} The current route segment.
   */
  GetCurrentRouteSegment(): string {
    return this.router.url.split('?').shift() || '/';
  }


  /**
   * Add the name of the current page to the toolbar
   * @param _page Nombre de la pagina
   */
  SetCurrentPage(_page: string): void {
    this.currentPage$.next(_page);
  }



  /**
   * Detects changes to the name of the current page
   * @returns Current Page Name
   */
  OnCurrentPageChange(): Observable<string> {
    return this.currentPage$.asObservable();
  }


  OnPageInit(): Observable<boolean> {
    return this.onPageInit$.asObservable();
  }
  PageInit(): void {
    this.onPageInit$.next(true);
  }
}


