import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {formatDate} from "@angular/common";
import {Repository} from "@clavisco/core";
import {IUserToken} from "../interfaces/i-token";
import {StorageKey} from "../enums/e-storage-keys";


/**
 * Interceptor to modify HTTP headers before sending requests.
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // If the URL includes 'token', skip modifying headers
    if(request.url.includes('token'))
    {
      return next.handle(request);
    }

    // Retrieve the user token from storage
    let userToken: IUserToken | null = Repository.Behavior.GetStorageObject<IUserToken>(StorageKey.Session);

    // Clone the request to modify headers
    let clonedRequest: HttpRequest<unknown> = request.clone();

    // Set Authorization header if a user token is present
    if (userToken) {
      let headers = clonedRequest.headers.set('Authorization', `Bearer ${userToken.access_token}`);
      clonedRequest = clonedRequest.clone({ headers });
    }

    // Add a timestamp header to track the request timestamp
    let headers = clonedRequest.headers
      .set('cl-ui-request-timestamp', formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss.SSS a', 'en'));
    clonedRequest = clonedRequest.clone({ headers });

    // Validates that it does not have the header indicating if it has files to avoid sending the content type
    if (!clonedRequest.headers.has('Request-With-Files')) {
      let headers = clonedRequest.headers.set('Content-Type', 'application/json');
      clonedRequest = clonedRequest.clone({ headers });
    }

    // Continue handling the modified request
    return next.handle(clonedRequest);
  }
}
