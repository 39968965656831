import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {ICompany} from "../../interfaces/i-companies";

@Component({
  selector: 'app-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.scss']
})
export class SelectCheckAllComponent implements OnInit {

  /**
   * FormControl that tracks the value of the checkbox state.
   */
  @Input() model: FormControl;

  /**
   * Array of items to be selected/deselected.
   */
  @Input() values: ICompany[] = [];

  /**
   * Text label for the 'select all' checkbox.
   */
  @Input() text = 'Todas';

  /**Event to capture the value selected to perform an action*/
  @Output() onSelectClicked: EventEmitter<ICompany[]> = new EventEmitter<ICompany[]>();

  /**
   * Initializes FormControl if not provided externally.
   */
  constructor() {
    this.model =  new FormControl();
  }

  /**
   * Lifecycle hook for additional initialization.
   */
  ngOnInit(): void {
  }

  /**
   * Checks if all items are selected.
   * @returns true if all items are selected, otherwise false.
   */
  isChecked(): boolean {
    return (
      this.model.value &&
      this.values.length &&
      this.model.value.length === this.values.length
    );
  }

  /**
   * Determines if the checkbox should be in an indeterminate state.
   * @returns true if some but not all items are selected, otherwise false.
   */
  isIndeterminate(): boolean {
    return !!(
      this.values.length &&
      this.model.value &&
      this.model.value.length &&
      this.model.value.length < this.values.length
    );
  }

  /**
   * Toggles the selection based on the checkbox change event.
   * @param change The checkbox change event object.
   */
  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.setValue(this.values);
      this.onSelectClicked.emit(this.model.value as ICompany[]);
    } else {
      this.model.setValue([]);
      this.onSelectClicked.emit(this.model.value as ICompany[]);
    }
  }

}
