import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PrincipalComponentResolver} from "./resolvers/principal-component.resolver";
import {VerifyUserTokenGuard} from "./guards/verify-user-token.guard";
import {ClearSessionGuard} from "./guards/clear-session.guard";

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/principal.module').then(m => m.PrincipalModule),
    canActivate: [VerifyUserTokenGuard],
    resolve: {resolvedData: PrincipalComponentResolver}
  },
  {
    path: 'login',
    loadChildren: () => import('./components/login-container/login-container.module').then(m => m.LoginCotainerModule),
    canActivate: [ClearSessionGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
