import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import {concatMap, map, Observable, of} from 'rxjs';
import {CLModalType, ModalService} from "@clavisco/alerts";
import {SharedService} from "../shared/shared.service";
import {IUserToken} from "../interfaces/i-token";
import {Repository} from "@clavisco/core";
import {StorageKey} from "../enums/e-storage-keys";

/**
 * Guard to verify the validity of the user token before navigating to a route.
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root'
})
export class VerifyUserTokenGuard implements CanActivate {
  constructor(private sharedService: SharedService,
              private modalService: ModalService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Retrieve the user token from storage
    let userToken: IUserToken | null = Repository.Behavior.GetStorageObject<IUserToken>(StorageKey.Session);

    // Check if a token exists
    let existToken: boolean = !!userToken;

    // Check if the token is valid (not expired and has access_token)
    let isValidToken: boolean = !!userToken && new Date(userToken['.expires']) > new Date(new Date().toUTCString()) && !!userToken.access_token;

    // Observable to handle token validation
    let observable$: Observable<boolean> = of(isValidToken)
      .pipe(
        concatMap(_validToken => {
          //If "existToken" is true then the reason of why the token is invalid is because it has expired
          if (!_validToken && existToken) {
            return this.modalService.Continue({
              type: CLModalType.INFO,
              subtitle: 'Su sesión ha caducado. Vuelva a iniciar sesión por favor.',
              disableClose: true
            })
              .pipe(
                map(y => {
                  this.sharedService.Logout();
                  return false;
                })
              );
          } else {
            return of(_validToken)
              .pipe(
                map(y => {
                  if (!y) {
                    this.sharedService.Logout();
                  }
                  return y;
                }));
          }
        })
      );

    return observable$;
  }

}
