import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SharedService} from "../shared/shared.service";
import {ModalService} from "@clavisco/alerts";


/**
 * Guard to clear the session
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root'
})
export class ClearSessionGuard implements CanActivate {
  constructor(private sharedService: SharedService)
  {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.sharedService.Logout(false);

    return true;
  }

}
