import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {filter, map, merge, Observable, of, startWith, Subject} from "rxjs";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T> implements OnInit {

  /** Observable to manage component filtering*/
  dataSource$:  Observable<T[]> = new Observable<T[]>();
  /** Local form control in the event that a form control or form group is not parametered */
  localControl:FormControl = new FormControl()
  /** List of data to be displayed in the component */
  @Input() options!: T[];
  /** Option to disable the component. Default values is false */
  @Input() disabled?: boolean = false;

  /** Option to clear the selected option. Defaults are false */
  @Input() cleanSelectOption?: boolean = false;
  /** Description you will have in the field */
  @Input() label!: string;

  /** Character to use to separate the properties of the object to display. Default value is a space (' ')*/
  @Input() bindingCharacter: string = ' ';

  /**Form control that will be used to manage the data*/
  @Input() control!: FormControl;

  /**Form group that will be used to manage the data*/
  @Input() form!:  FormGroup;
  /**Form group name that will be used to manage the data in the from control*/
  @Input() controlName!: string;

  /**Options you want to Display for the Object*/
  @Input() displayProperties: string[] = [];

  /**Event to capture the value selected to perform an action*/
  @Output() onAutocompleteClicked: EventEmitter<T> = new EventEmitter<T>();

  onFocusInput$ = new Subject<true>()

  @ViewChild('inputField') inputField!: ElementRef<HTMLInputElement>;

  /**Property of the object with which the option is loaded according to the value*/
  @Input() propertyToLoadObject!: string;


  constructor() {}

  ngOnInit(): void {
    this.OnLoad();
  }

  /**
   * The filtering action for the component is recorded
   */
  OnLoad(): void {
    this.ShowOptionsOnFocusAndValuesChanges();
    this.ParseFormControlValueStringToObject();

  }

  /**
   * Function used as the displayWith function for the mat-autocomplete.
   * It wraps the displayFn and provides additional display properties.
   * @param option The option to display.
   * @returns The string representation of the option.
   */
  DisplayWithWrapper = (option: T) => {
    return this.DisplayFn(option, this.displayProperties);
  }

  /**
   * Function to determine the display string for an option.
   * @param _option The business object to display.
   * @param _properties The properties of the option object to include in the display string.
   * @returns The display string for the option object.
   */
  DisplayFn(_option: T, _properties: string []): string {
    if(!this.cleanSelectOption){
      return _option && Object.keys(_option).length ? _properties.map(prop => (_option as any)[prop]).join(this.bindingCharacter) : '';
    }
    return '';
  }

  /**
   * Function to determine the display string for an option.
   * @param _option The option object to display.
   * @returns The display string for the option object.
   */
  Display(_option: T): string {
    return _option && Object.keys(_option).length ? this.displayProperties.map(prop => (_option as any)[prop]).join(this.bindingCharacter) : '';
  }

  /**
   * Function called when an option is selected from the autocomplete.
   * @param _option The selected option.
   */
  public onSelectOption(_option: T): void {
    this.onAutocompleteClicked.emit(_option);
    if(this.cleanSelectOption){
      this.dataSource$ = of(this.options);
    }
  }

  /**
   * Function to filter the options based on the provided value.
   * @param _value The value to filter the options with.
   * @returns The filtered options array.
   */
  FilterOptions(_value: string | T): T[]
  {
    if(typeof _value === 'undefined' || _value === null) return this.options;

    if(typeof _value !== 'string')
    {
      return this.options.filter(option => this.displayProperties.some(prop => (option as any)[prop].toString().toLowerCase().includes((_value as any)[prop].toString().toLowerCase())));
    }

    let filterValue = _value.toLowerCase();

    return this.options.filter(option =>
      this.displayProperties.some(prop => (option as any)[prop].toString().toLowerCase().includes(filterValue))
    );
  }

  ShowOptionsOnFocusAndValuesChanges(): void
  {
    let formControl = ((this.form && this.controlName) ? this.form.get(this.controlName) : false)  || this.control || this.localControl;
    merge(formControl.valueChanges, this.onFocusInput$)
      .pipe(
        startWith(''),
        map(value => typeof value === 'boolean' ? formControl.value : value)
      )
      .subscribe({
        next: (value: string | T) => {
          this.dataSource$ =  of(this.FilterOptions(value));
        }
      });
  }

  /**
   * This method loads the object corresponding to the value of the form into the form
   * @constructor
   */
  ParseFormControlValueStringToObject(): void{
    if(this.form && this.controlName && this.propertyToLoadObject){
      let formValue = this.form.get(this.controlName)?.value;
      if( formValue && typeof formValue !== 'object' ){
        this.form.get(this.controlName)?.patchValue(this.options.find(data => (data as any)[this.propertyToLoadObject] == formValue))

      }
    }
  }

  OnFocus() {
      this.inputField.nativeElement.focus();
  }

}
