import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IUser} from "../interfaces/i-users";
import {IRole} from "../interfaces/i-roles";
import {IValidValuesByField} from "../interfaces/i-valid-values";
import {IDepartment} from "../interfaces/i-departments";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly CONTROLLER = 'Users'
  constructor(private http: HttpClient) { }

  /**
   * Execute a request to get the users or single user depending if the _id param is specified
   * @param _id The id of the user to request
   * @constructor
   */
  Get<T>(_onlyActiveUsers: boolean | null, _id?: number): Observable<ICLResponse<T>>
  {
    let path = this.CONTROLLER;

    if(_id)
    {
      path += `/${_id}`;
    }

    return this.http.get<ICLResponse<T>>(path, {
      params: _onlyActiveUsers != null ? {
        onlyActiveUsers: _onlyActiveUsers
      } : undefined
    });
  }

  /**
   * Retrieves roles by user
   * @param _user The id of the user for which roles are to be retrieved.
   * @returns {Observable<Structures.Interfaces.ICLResponse<IRole[]>>} An observable containing the response with roles.
   */
  GetRoleByUser(_user: number): Observable<Structures.Interfaces.ICLResponse<IRole[]>> {

    let path =  `${this.CONTROLLER}/${_user}/Roles`;

    return this.http.get<Structures.Interfaces.ICLResponse<IRole[]>>(path)
  }


  /**
   * Execute a request to create the user specified
   * @param _user The user to create
   * @constructor
   */
  Post(_user: IUser): Observable<ICLResponse<IUser>>
  {
    return this.http.post<ICLResponse<IUser>>(this.CONTROLLER, _user);
  }

  /**
   * Execute a request to update the user specified
   * @param _user The user to update
   * @constructor
   */
  Patch(_user: IUser): Observable<ICLResponse<IUser>>
  {
    return this.http.patch<ICLResponse<IUser>>(this.CONTROLLER, _user);
  }

  /**
   * Execute a request to update the user roles asigned
   * @param _user The user to update
   * @param _roles The roles to asigned
   * @constructor
   */
  PatchRoleByUser(_user: number, _roles: IRole[]): Observable<Structures.Interfaces.ICLResponse<IRole[]>>
  {
    let path =  `${this.CONTROLLER}/${_user}/Roles`;
    return this.http.patch<Structures.Interfaces.ICLResponse<IRole[]>>(path, _roles);
  }


  /**
   * Execute a request to get valid values from the database.
   * @constructor
   */
  GetValidValuesUserRequestFields(): Observable<Structures.Interfaces.ICLResponse<IValidValuesByField[]>> {
    return this.http.get<Structures.Interfaces.ICLResponse<IValidValuesByField[]>>(`${this.CONTROLLER}/ValidValuesByUserRequestFields`)
  }

  /**
   * Execute a request to get user default department
   * @constructor
   */
  GetUserDefaultDepartment(_userId: number): Observable<Structures.Interfaces.ICLResponse<IDepartment>> {
    return this.http.get<Structures.Interfaces.ICLResponse<IDepartment>>(`${this.CONTROLLER}/${_userId}/DefaultDepartment`)
  }


}
