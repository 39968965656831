import {Pipe, PipeTransform} from '@angular/core';
import {WorkflowCompanyStates, WorkflowsStates} from "../enums/enums";

@Pipe({
  name: 'workflowStateIcon'
})
export class WorkflowStateIconPipe implements PipeTransform {

  transform(value: WorkflowsStates | WorkflowCompanyStates, ...args: unknown[]): string {
    switch (value) {
      case WorkflowsStates.RETRY:
        return "replay";
      case WorkflowsStates.APPROVE:
        return "check_circle";
      case WorkflowCompanyStates.PENDING:
      case WorkflowsStates.PENDING:
        return "pending";
      case WorkflowsStates.FINALIZED:
        return "task_alt"
      case WorkflowsStates.EXECUTING:
        return "refresh";
      case WorkflowsStates.ERROR:
        return "error_outline";
      default:
        return "";
    }
  }

}
