import {EnvironmentType} from "../app/enums/enums";

export const environment = {
  production: false,
  apiUrl: 'https://gcdmaapidev.clavisco.com/api/',
  name: `Ambiente de desarrollo`,
  type: EnvironmentType.Development,
  env: 'Development',
  recatchaSiteKey: ''
};
