import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IPermission, IPermissionByUser, IRoutePermission} from "../interfaces/i-permissions";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private readonly CONTROLLER = 'Permissions';

  constructor(private http: HttpClient) {
  }

  /**
   * Retrieves permission
   * @param _id Optional parameter for filtering permission by id.
   * @param _isActive Optional parameter indicating whether to retrieve active permission only. Default value is false
   * @returns {Observable<Structures.Interfaces.ICLResponse<T>>} An observable containing the response with permission.
   */
  Get<T>(_id?: number, _isActive = false): Observable<Structures.Interfaces.ICLResponse<T>> {
    let path = this.CONTROLLER;

    if (_id) {
      path += `/${_id}`;
    } else {
      path += `?active=${_isActive}`;
    }

    return this.http.get<Structures.Interfaces.ICLResponse<T>>(path)
  }


  /**
   * Retrieves filtered permissions based on search criteria.
   * @param _searchCriteria The search criteria used to filter permissions.
   * @param _shouldBeActive Optional parameter indicating whether to retrieve only active permissions. Default value is false
   * @returns {Observable<ICLResponse<IPermission[]>>} An observable containing the permissions.
   */
  GetFilteredPermissions(_searchCriteria: string, _shouldBeActive: boolean = false): Observable<ICLResponse<IPermission[]>> {

    return this.http.get<ICLResponse<IPermission[]>>(`${this.CONTROLLER}/Filter`, {
      params : {
        shouldBeActive: _shouldBeActive,
        searchCriteria : _searchCriteria || ''
    }});
  }

  /**
   * Retrieves filtered permissions assigned a curren user.
   * @returns {Observable<ICLResponse<IPermissionByUser[]>>} An observable containing the permissions.
   */
  GetPermissionsByUser(): Observable<ICLResponse<IPermissionByUser[]>> {

    return this.http.get<ICLResponse<IPermissionByUser[]>>(`${this.CONTROLLER}/UserPermissions`);
  }

  /**
   * Retrieves filtered route permissions.
   * @returns {Observable<ICLResponse<IRoutePermission[]>>} An observable containing the route permissions.
   */
  GetRoutePermissions(): Observable<ICLResponse<IRoutePermission[]>> {

    return this.http.get<ICLResponse<IRoutePermission[]>>(`${this.CONTROLLER}/RoutePermissions`);
  }

}
