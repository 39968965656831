import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

/**
 * Interceptor to modify URLs before sending requests.
 * @implements {HttpInterceptor}
 */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // Temporal validation: Skip modification for specific URL
    if (request.url.includes('assets/data/menu.json')) {
      return next.handle(request);
    }

    // Skip modification if the request URL already contains the base API URL
    if (request.url.includes(environment.apiUrl)) {
      return next.handle(request);
    }

    // Clone the request and prepend the base API URL to the request URL
    request = request.clone({ url: `${environment.apiUrl}${request.url}` });

    // Continue handling the modified request
    return next.handle(request);
  }
}
