import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IActionButton} from "../../interfaces/i-action-buttons";
import {FormGroup} from "@angular/forms";
import {IPermission} from "../../interfaces/i-permissions";
import {SharedService} from "../shared.service";
import {CLPrint, Structures} from "@clavisco/core";

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit, AfterViewInit {
  @Input() actionButtons: IActionButton[] = [];
  @Input() isIntoMatTab: boolean = true;
  @Input() form?: FormGroup;
  @Input() userPermissions: IPermission[] = [];
  @Input() customClass: string = '';
  @Input() disabledIf: boolean = false;


  @Output() onActionButtonClicked: EventEmitter<IActionButton> = new EventEmitter<IActionButton>();
  constructor(private sharedService: SharedService) { }

  ngAfterViewInit(): void {
    this.sharedService.PageInit();
  }
  ngOnInit(): void {
    if(!this.actionButtons || !this.actionButtons.length)
    {
      CLPrint('No action buttons was provide', Structures.Enums.CL_DISPLAY.WARNING);
      this.actionButtons = [];
    }
  }

  EmitActionButtonClickEvent(_actionButton: IActionButton): void
  {
    this.onActionButtonClicked.emit(_actionButton);
  }
}
