import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import {IUser} from "../interfaces/i-users";

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  private readonly CONTROLLER = 'Departments';
  constructor(private http: HttpClient) { }

  /**
   * Send a request to get all or only active departments or a single de department depending of parameters values
   * @param _onlyActiveDepartments Indicates if only active departments will be requested
   * @param _id The id of the department that will be requested
   * @constructor
   */
  Get<T>(_onlyActiveDepartments: boolean | null, _id?: number): Observable<Structures.Interfaces.ICLResponse<T>>
  {
    let path = this.CONTROLLER;

    if(_id)
    {
      path += `/${_id}`;
    }

    return this.http.get<Structures.Interfaces.ICLResponse<T>>(this.CONTROLLER, {
      params: _onlyActiveDepartments != null ? { onlyActiveDepartments: _onlyActiveDepartments } : undefined
    })
  }

  /**
   * Retrieves users belonging to a specific department.
   * @param _departmentId The ID of the department for which users are to be retrieved.
   * @returns An observable containing the response with users belonging to the specified department.
   */
  GetUsers(_departmentId: number): Observable<Structures.Interfaces.ICLResponse<IUser[]>>
  {
    return this.http.get<Structures.Interfaces.ICLResponse<IUser[]>>(`${this.CONTROLLER}/${_departmentId}/Users`);
  }
}
