<mat-form-field appearance="outline" class="select-width">
  <mat-label>{{label ? label: 'Compañías'}}</mat-label>

  <ng-container *ngIf="controlName && form; else formControlInput">
    <form [formGroup]="form">
      <mat-select [formControlName]="controlName" (selectionChange)="onSelectOption($event.value)" [compareWith]="CompareCompany" multiple>
        <mat-option *ngFor="let company of companies" [value]="company">
          {{company.Name}}
        </mat-option>
      </mat-select>
    </form>
  </ng-container>

  <ng-template  #formControlInput>
    <mat-select [formControl]="control" (selectionChange)="onSelectOption($event.value)" [compareWith]="CompareCompany" multiple>
      <app-select-check-all [model]="control" [values]="companies" (onSelectClicked)="onSelectOption($event)">
      </app-select-check-all>
      <mat-option *ngFor="let company of companies" [value]="company">
        {{company.Name}}
      </mat-option>
    </mat-select>
  </ng-template>

</mat-form-field>
