import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoginModule} from "@clavisco/login";
import {Miscellaneous} from "@clavisco/core";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LinkerService} from "@clavisco/linker";
import {AlertsService} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";
import {UrlInterceptor} from "./interceptors/url.interceptor";
import {HeadersInterceptor} from "./interceptors/headers.interceptor";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {SharedModule} from "./shared/shared.module";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {environment} from "../environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      LoginModule,
      HttpClientModule,
      SharedModule,
      BrowserAnimationsModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: 'OverlayService',
      useClass: OverlayService
    },
    {
      provide: 'AlertsService',
      useClass: AlertsService
    },
    {
      provide: 'LinkerService',
      useClass: LinkerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.PagedRequestInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recatchaSiteKey
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
