/**
 * Enum representing different keys used for storing data in the storage.
 * @enum {string}
 */
export enum StorageKey {
  Session = 'Session',
  CurrentCompany = 'CurrentCompany',
  CurrentUserAssign = 'UserAssign',
  CurrentSession = 'CurrentSession',
  Ports = 'Ports',
  Permissions ='Permissions',
  Menu= 'Menu',
}
