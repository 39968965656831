/**
 * Enum representing different environment types.
 * @enum {string}
 */
export enum EnvironmentType {
  Development = 'Development',
  Testing = 'Testing',
  Production = 'Production'
}

/**
 * Enum representing different setting codes.
 * @enum {string}
 */
export enum SettingCode {
  Menu = 'Menu'
}

/**
 * Enum representing different linker events codes.
 * @enum {string}
 */
export enum LinkerEvent
{
  CurrentPage = 'CURRENT_PAGE',
  CurrentCompany = 'CURRENT_COMPANY',
  ActionButton = 'ACTION_BUTTON'
}

/**
 * Enum representing different strutures types.
 * @enum {string}
 */
export enum StructuresTypes
{
  ItemTypes = 'ItemTypes',
  Business = 'Business',
  Families = 'Families',
  Groupings = 'Groupings',
  Contries = 'Contries',
  Companies = 'Companies',
  WorkFlowStates = 'WorkflowStates',
  ProvisioningModes = 'ProvisioningModes',
  ExplosionMaterialsList = 'ExplosionMaterialsList',
  ProductionUnits = 'ProductionUnits'
}

/**
 * Enumeration representing different types of users assigned in the workflow
 * @enum {string}
 */
export enum TypesUsersWorkFlow
{
  ResponsibleUsers = 'RU',
  ReviewerUsers= 'RVU',
  ApproverUsers= 'AU'

}

/**
 * Enumeration representing the states of workflows.
 */
export enum WorkflowsStates {
  /**
   * Indicates if the workflow was pending
   */
  PENDING = 'PD',

  /**
   * Indicates if the workflow was executing
   */
  EXECUTING = 'EX',

  /**
   * Indicates if the workflow was approved
   */
  APPROVE = 'AP',

  /**
   * Indicates whether the workflow should be error
   */
  ERROR = 'ER',
  /**
   * Indicates whether the workflow should be retried
   */
  RETRY = 'RT',

  /**
   * Indicates whether the workflow should be finalized
   */
  FINALIZED = 'FN',

  /**
   * Indicates whether the workflow should be only a template
   */
  TEMPLATE = 'TM'

}

/**
 * Enumeration representing the states of workflows connections.
 */
export enum WorkflowCompanyStates {
  /**
   * Indicates if the workflow was pending
   */
  PENDING = 'PD',

}

/**
 * Enumeration representing the permissions to validate in the functions
 */
export enum PermissionValidate {
  /**
   * Represents the access permission to the workflow monitoring module
   */
  DataMasterMonitorWorkflowAccess = 'DataMaster_MonitorWorkflow_Access',

  /**
   * Represents the access permission to the workflow fields completion module
   */
  DataMasterWorkflowFieldsCompletionAccess = 'DataMaster_WorkflowFieldsCompletion_Access',

  /**
   * Represents the permission to access the create workflows property
   */
  DataMasterCreateWorkflowAccess = 'DataMaster_CreateWorkflow_Access',

  /**
   * Represents the permission to access the reuse workflows property
   */
  DataMasterReuseWorkFlowAccess = 'DataMaster_ReuseWorkFlow_Access',

  /**
   * Represents the permission to access the discard workflows property
   */
  DataMasterDiscardRequestAccess = 'DataMaster_DiscardRequest_Access',

  /**
   * Represents permission to access the option to approve a workflow
   */
  DataMasterApproveWorkFlowAccess = 'DataMaster_ApproveWorkFlow_Access',

  /**
   * Represents permission to access the option to reject a workflow
   */
  DataMasterRejectWorkflowAccess = 'DataMaster_RejectWorkflow_Access',

  /**
   * Allow the user to complete stages in the workflow
   */
  DataMasterWorkflowFieldsCompletionCompleteStage = 'DataMaster_WorkflowFieldsCompletion_CompleteStage',

  /**
   * Allow the user to save changes of the fields
   */
  DataMasterWorkflowFieldsCompletionSaveChanges = 'DataMaster_WorkflowFieldsCompletion_SaveChanges'
}

/**
 * Enumeration representing the states of workflows.
 */
export enum WorkflowDepartmentStates {

  /**
   * Indicates if department fields have been empty
   */
  EMPTY = 'EM',
  /**
   * Indicates if department fields have been saved
   */
  SAVED = 'SV',

  /**
   * Indicates if department fields have been completed
   */
  COMPLETED = 'CM',

  /**
   * Indicates if department fields have been rejected
   */
  REJECTED = 'RJ'

}



