import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {ICompany} from "../interfaces/i-companies";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private readonly CONTROLLER = 'Companies'
  constructor(private http: HttpClient) { }

  /**
   * Execute a request to get the users or single user depending if the _id param is specified
   * @param _isActive The status active of the company
   * @param _id Specified id of the company to search
   * @constructor
   */
  Get<T>(_isActive? : boolean | null, _id?: number,): Observable<Structures.Interfaces.ICLResponse<T>> {
    let path = this.CONTROLLER;

    if (_id) {
      path += `/${_id}`;
    }

    return this.http.get<Structures.Interfaces.ICLResponse<T>>(path, {
        params: _isActive != null ? {
          active: _isActive
        } : undefined
      })
  }

  /**
   * Execute a request to create the company specified
   * @param _company The company to create
   * @constructor
   */
  Post(_company: ICompany): Observable<ICLResponse<ICompany>>
  {
    return this.http.post<ICLResponse<ICompany>>(this.CONTROLLER, _company);
  }

  /**
   * Execute a request to update the company specified
   * @param _company The company to update
   * @constructor
   */
  Patch(_company: ICompany): Observable<ICLResponse<ICompany>>
  {
    return this.http.patch<ICLResponse<ICompany>>(this.CONTROLLER, _company);
  }

}
