import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {catchError, forkJoin, map, Observable, of, tap} from 'rxjs';
import {IPrincipalComponentResolvedData} from "../interfaces/i-resolvers";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {SettingsService} from "../services/settings.service";
import {ISettings} from "../interfaces/i-settings";
import {SettingCode} from "../enums/enums";
import {PermissionService} from "../services/permission.service";

/**
 * Resolver for the principal component to fetch and provide resolved data.
 * @implements {Resolve<IPrincipalComponentResolvedData | null>}
 */
@Injectable({
  providedIn: 'root'
})
export class PrincipalComponentResolver implements Resolve<IPrincipalComponentResolvedData | null> {

  constructor(private settingService: SettingsService,
              private alertsService: AlertsService,
              private permissionService: PermissionService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPrincipalComponentResolvedData | null> {
    return forkJoin({
      setting: this.settingService.Get<ISettings>(SettingCode.Menu),
      permissions: this.permissionService.GetPermissionsByUser()
    }).pipe(
      map(res => {
        return {
          Setting : res.setting.Data,
          Permissions: res.permissions.Data
        } as IPrincipalComponentResolvedData
      }),
      catchError(err => {
        // Display an alert on error and return null
        this.alertsService.ShowAlert({HttpErrorResponse: err});
        return of(null)
      })
  )
  }
}
