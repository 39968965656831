import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {environment} from "../../environments/environment";
import {CLModalType, ModalService} from "@clavisco/alerts";
import {SharedService} from "../shared/shared.service";

/**
 * Interceptor to handle HTTP errors and perform actions based on the error status.
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private sharedService: SharedService,
              private modalService: ModalService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((res: any) => {
        // Check if the error is an unauthorized status related to the API
        if (res['status'] === HttpStatusCode.Unauthorized && res['url'].includes(environment.apiUrl)) {
          // Show a modal with an unauthorized token message
          this.modalService.Continue({
            title: 'Token de autorización no válido',
            type: CLModalType.INFO
          }).subscribe();

          // Logout the user
          this.sharedService.Logout();
        }

        return throwError(() => res);
      }));
  }
}
