import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ICompany} from "../../interfaces/i-companies";
import {CompanyService} from "../../services/company.service";
import {OverlayService} from "@clavisco/overlay";
import {finalize} from "rxjs";
import {AlertsService} from "@clavisco/alerts";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {

  companies: ICompany[] = [];

  /**Form control that will be used to manage the data*/
  @Input() control: FormControl = new FormControl();

  /** Description you will have in the field */
  @Input() label!: string;

  /**Form group that will be used to manage the data*/
  @Input() form!:  FormGroup;
  /**Form group name that will be used to manage the data in the from control*/
  @Input() controlName!: string;

  /**Event to capture the value selected to perform an action*/
  @Output() onSelectClicked: EventEmitter<ICompany[]> = new EventEmitter<ICompany[]>();

  constructor(private companyService: CompanyService,
              private overlayService: OverlayService,
              private alertsService: AlertsService) { }

  ngOnInit(): void {
    this.LoadCompanies();
  }

  /**
   * Function called when an option is selected from the select.
   * @param _option The selected option.
   */
  public onSelectOption(_option: ICompany[]): void {
    this.onSelectClicked.emit(_option);
  }

  /**
   * Load companies configurations
   * @constructor
   */
  LoadCompanies(): void{
    this.overlayService.OnGet();
    this.companyService.Get<ICompany[]>(true)
      .pipe(finalize(()=>this.overlayService.Drop()))
      .subscribe({
        next: ((callback) =>{
          this.companies = callback.Data || [];
        }),
        error: ((error)=> this.alertsService.ShowAlert({HttpErrorResponse: error}))
      })
  }

  /**
   * Custom comparison function for mat-select.
   * Used to determine if two objects are equal and should be selected in the mat-select.
   * @param company The first Company object to compare.
   * @param companySelect The second Company object to compare.
   * @returns true if the identifiers (id) of the objects are equal, false otherwise.
   */
  CompareCompany(company: ICompany, companySelect: ICompany): boolean {
    return company && companySelect && company.Id === companySelect.Id;
  }


}
